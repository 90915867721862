<!--
 * @Author: gaojingran
 * @Date: 2021-04-07 16:29:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-10 10:29:48
 * @Description: 订单详情
-->
<style lang="less" scoped>
@import './index.less';

.ant-space-item {
  // width: 95%;
  overflow: hidden;
  padding-right: 5px;
}

::v-deep {
  .download-wrap {
    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // span {
    //   word-break: normal;
    //   width: auto;
    //   display: block;
    //   white-space: pre-wrap;
    //   word-wrap: break-word;
    //   overflow: hidden;
    // }
  }
}
</style>

<template>
  <a-spin :spinning="loading">
    <NeoPageLayout :showFooter="true">
      <div class="order-info">
        <!-- title -->
        <div class="order-title">
          <span class="title ellipsis" v-if="info.name" :title="info.name">
            {{ info.name }}
          </span>
          <!-- <span class="status" v-if="info.isTerminate === 1">{{ localeDict.orderStatus[info.status] | defined }}</span>
          <span class="status" v-else>{{ localeDict.orderStatus[Math.min(9, info.status)] | defined }}</span> -->
          <span class="status">{{ localeDict.orderStatus[info.orderStatus] | defined }}</span>
          <!-- 待确认终稿 & 有autoConfirmDate - 当前时间倒推 -->
          <span class="alert" v-if="showAutoConfirmDate">
            <a-icon type="exclamation-circle" theme="filled" />
            <span class="pl-1">
              {{ $t('orderInfo.autoConfirmDate', autoConfirmDate) }}
            </span>
          </span>
        </div>
        <!-- summarize -->
        <div class="order-summarize">
          <template v-if="info.isOrderSpm === 0">
            <!-- 下单经理 -->
            <div class="summarize-item" v-if="$role('order_orderInfo_managerVendor')">
              <span class="label">{{ $t('orderInfo.managerVendor') }}:</span>
              <span class="value">{{ info.presentManagerName | defined }}</span>
            </div>
            <!-- 联系方式 -->
            <div class="summarize-item">
              <span class="label">{{ $t('orderInfo.contact') }}:</span>
              <span class="value">
                <span>{{ (info.presentManagerPhone || null) | defined }}</span>
              </span>
            </div>
            <!-- 报价金额 -->
            <div class="summarize-item">
              <span class="label">{{ $t('orderInfo.quote_amount') }}:</span>
              <span class="value">
                <span v-if="currencyUnit">{{ currencyUnit[1] }}</span>
                <!-- 在确认报价后&&有报价金额才显示 -->
                <span v-if="info.status > 2 && $is.Defined(info.quotePrice)">{{ info.quotePrice | countFormat }}</span>
                <span v-else>---</span>
              </span>
            </div>
            <!-- 订单交期 -->
            <div class="summarize-item">
              <span class="label">{{ $t('orderInfo.order_delivery') }}:</span>
              <!-- <span class="value" v-if="$is.Defined(info.feedbackDueDate)">{{
                info.feedbackDueDate | dateFormat
              }}</span>
              <span class="value" v-else>{{ info.dueDate | dateFormat }}</span> -->
              <span class="value">{{ info.dueDate | dateFormat }}</span>
            </div>
          </template>
          <template v-else>
            <!-- 交付经理 -->
            <div class="summarize-item" v-if="$role('order_orderInfo_manager')">
              <span class="label">{{ $t('orderInfo.manager') }}:</span>
              <span class="value">{{ info.supplierPmName | defined }}</span>
            </div>
            <!-- 下单经理 -->
            <div class="summarize-item" v-if="$role('order_orderInfo_managerVendor')">
              <span class="label">{{ $t('orderInfo.managerVendor') }}:</span>
              <span class="value">{{ info.presentManagerName | defined }}</span>
            </div>
            <!-- 联系方式 -->
            <div class="summarize-item">
              <span class="label">{{ $t('orderInfo.contact') }}:</span>
              <span class="value">
                <!-- 供应商下单经理电话 -->
                <span v-if="$role('order_orderInfo_createQuote')">{{
                  (info.presentManagerPhone || null) | defined
                }}</span>
                <!-- 下单方交付经理电话 -->
                <span v-else>{{ (info.supplierPmPhone || null) | defined }}</span>
              </span>
            </div>
            <!-- 报价金额 -->
            <div class="summarize-item">
              <span class="label">{{ $t('orderInfo.quote_amount') }}:</span>
              <span class="value">
                <span v-if="currencyUnit">{{ currencyUnit[1] }}</span>
                <!-- 在确认报价后&&有报价金额才显示 -->
                <span v-if="info.status > 2 && $is.Defined(info.quotePrice)">{{ info.quotePrice | countFormat }}</span>
                <span v-else>---</span>
              </span>
            </div>
            <!-- 订单交期 -->
            <div class="summarize-item">
              <span class="label">{{ $t('orderInfo.order_delivery') }}:</span>
              <!-- <span class="value" v-if="$is.Defined(info.feedbackDueDate)">{{
                info.feedbackDueDate | dateFormat
              }}</span>
              <span class="value" v-else>{{ info.dueDate | dateFormat }}</span> -->
              <span class="value">{{ info.dueDate | dateFormat }}</span>
            </div>
          </template>
        </div>
        <div class="mt-4 mb-4 c-1 fs-2">{{ $t('orderInfo.progress') }}:</div>
        <!-- 订单进度 -->
        <template>
          <TimeStep v-if="orderProgress.length" :options="orderProgress" class="mb-4" @loaded="TimeStepLoaded" />
          <a-empty v-else />
        </template>
        <!-- 翻译进度 -->
        <div class="translate_progress">
          <div class="title">
            <span>{{ $t('orderInfo.translate_progress') }}</span>
          </div>
          <div class="table">
            <a-table
              class="no-br head-bg"
              size="middle"
              rowKey="targetCode"
              :bordered="true"
              :data-source="neoProduceData"
              :pagination="false"
            >
              <a-table-column key="targetCode" :ellipsis="true" align="center" :title="$t('orderInfo.table_target')">
                <template slot-scope="text">
                  <span>{{ $store.getters['app/getLangNameByCode'](text.targetCode) | defined }}</span>
                </template>
              </a-table-column>

              <a-table-column key="weightWordCount" :ellipsis="true" :title="$t('orderInfo.table_word_count')">
                <template slot-scope="text">
                  <!-- 除等待报价不显示字数其他都显示 -->
                  <span v-if="info.status > 1">{{ text.weightWordCount | defined }}</span>
                  <span v-else>---</span>
                </template>
              </a-table-column>

              <a-table-column key="currentStage" :ellipsis="true" :title="$t('orderInfo.table_step')">
                <template slot-scope="text">
                  <template v-if="[5, 7, 9, 8, 2, 1, 3].includes(info.status) || !text.currentStage">
                    <span>---</span>
                  </template>
                  <template v-else>
                    <span>
                      {{ text.currentStage | currentStage }}
                    </span>
                    <!-- <span v-else>
                      {{ $t('orderInfo.translation') }} ({{ ~~text.currentStage.replace('Translation', '') + 1 }})
                    </span> -->
                  </template>
                </template>
              </a-table-column>

              <a-table-column key="stageProgress" :width="220" :title="$t('orderInfo.table_step_progress')">
                <template slot-scope="text">
                  <div class="mr-4">
                    <a-progress
                      v-if="![5, 7, 9, 8, 2, 1, 3].includes(info.status) && $is.Defined(text.currentStage)"
                      :percent="text.stageProgress"
                    />
                    <!-- <a-progress
                      v-if="$is.Defined(text.currentStage) && !['TR', 'ER', 'QR'].includes(text.currentStage)"
                      :percent="Number(text.stageProgress)"
                    /> -->
                    <span v-else>---</span>
                  </div>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </div>
        <!-- 订单信息 -->
        <a-row class="mt-4" :gutter="20">
          <a-row>
            <a-col :span="9">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.service_type') }}:</span>
                <span
                  class="info ellipsis"
                  :style="this.isModify('serviceType') ? 'color:#F56C6C;' : ''"
                  :title="info.serviceLabel | defined"
                >
                  <!-- {{ $store.getters['app/getDictLabel']('SERVICE_TYPE', info.serviceType) | defined }} -->
                  {{ info.serviceLabel | defined }}
                  <span v-if="info.composeType" :style="this.isModify('composeType') ? 'color:#F56C6C;' : ''">{{
                    $t('orderInfo.with_compose')
                  }}</span>
                </span>
              </div>
            </a-col>
            <a-col :span="9">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.doc_type') }}:</span>
                <span
                  class="info ellipsis"
                  :style="isModify('documentType') ? 'color:#F56C6C;' : ''"
                  :title="info.documentLabel | defined"
                >
                  <!-- {{ $store.getters['app/getDictLabel']('DOC_TYPE', info.documentType) | defined }} -->
                  {{ info.documentLabel | defined }}
                </span>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="9">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.product_line') }}:</span>
                <span
                  class="info ellipsis"
                  :style="isModify('productLineId') ? 'color:#F56C6C;' : ''"
                  :title="info.productLineName | defined"
                  >{{ info.productLineName | defined }}</span
                >
              </div>
            </a-col>
            <a-col :span="9">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.lang_pair') }}:</span>
                <span
                  class="info ellipsis"
                  :style="isModify('sourceCode') || isModify('targetCodeList') ? 'color:#F56C6C;' : ''"
                  :title="
                    $store.getters['app/getLangNameByCode'](info.sourceCode) +
                    '>' +
                    $store.getters['app/getLangNameByCode'](info.targetCodeList)
                  "
                >
                  {{ $store.getters['app/getLangNameByCode'](info.sourceCode) | defined }}
                  >
                  {{ $store.getters['app/getLangNameByCode'](info.targetCodeList) | defined }}
                </span>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="9" v-if="$role('order_orderInfo_neoCode')">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.neo_code') }}:</span>
                <span
                  class="info ellipsis"
                  :style="isModify('sourceCode') || isModify('targetCodeList') ? 'color:#F56C6C;' : ''"
                  :title="info.neoSourceCode + '>' + info.neoTargetCodeList"
                >
                  {{ info.neoSourceCode | defined }} >
                  {{ info.neoTargetCodeList | defined }}
                </span>
              </div>
            </a-col>
            <!-- <a-col :span="9" v-if="$role('order_orderList_customer')"> -->
            <a-col :span="9" v-if="$role('order_orderList_provider')">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.industry') }}:</span>
                <span class="info ellipsis" :title="info.field | defined">{{ info.field | defined }}</span>
              </div>
            </a-col>
          </a-row>
          <a-col :span="24" style="z-index: 1">
            <div class="order-info-item">
              <span class="label pl-2" style="margin-left: -10px">{{ $t('orderInfo.order_declare') }}:</span>
              <div class="info">
                <pre
                  class="m0 text-wrap"
                  :style="isModify('remark') ? 'color:#F56C6C;' : ''"
                  v-if="info.remark"
                  v-html="info.remark"
                />
                <span v-else>---</span>

                <div class="download-wrap mt-1" v-if="attachmentList.length">
                  <a-space :size="10">
                    <a
                      v-for="item in attachmentList"
                      :key="item.id"
                      class="p0 flex"
                      type="link"
                      :title="item.name"
                      @click="linkDownload(item.url)"
                    >
                      <span class="dis-ib ellipsis" style="width: 60%">{{ item.name }}</span>
                      <a-icon class="pt-1" type="download" />
                    </a>
                  </a-space>
                </div>
              </div>
            </div>
          </a-col>
          <a-row>
            <a-col :span="9">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.translate_file') }}:</span>
                <div class="info">
                  <div class="download-wrap">
                    <a-space :size="10" style="width: 100%; overflow: hidden">
                      <!-- <div
                        v-for="item in info.sourceFileList"
                        class="_point custom-item"
                        :key="item.id"
                        @click="linkDownload(item.url)"
                      >
                        <a class="downdown">{{ item.name }}</a>
                        <a-icon type="download" />
                      </div> -->
                      <a
                        :block="true"
                        v-for="item in info.sourceFileList"
                        :key="item.id"
                        style="width: 100%"
                        type="link"
                        :title="item.name"
                        class="flex"
                        @click="linkDownload(item.url)"
                      >
                        <span class="dis-ib ellipsis" style="width: 60%">{{ item.name }}</span>
                        <a-icon class="pt-1" type="download" />
                      </a>
                    </a-space>
                  </div>
                </div>
              </div>
            </a-col>

            <!-- 供应商实体 -->
            <a-col :span="9">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.provider') }}:</span>
                <span class="info ellipsis" :title="info.supplierName | defined">{{
                  info.supplierName | defined
                }}</span>
              </div>
            </a-col>
          </a-row>

          <!-- 终稿说明 -->
          <a-row
            v-if="
              info.status >= 5 &&
              finalDraftHistory.length > 0 &&
              finalDraftHistory[0].targetFileList &&
              finalDraftHistory[0].targetFileList[0] &&
              finalDraftHistory[0].targetFileList[0].remark
            "
          >
            <a-col :span="24">
              <div class="order-info-item">
                <span class="label">{{ $t('orderInfo.final_document_show') }}:</span>
                <div class="info">
                  <div class="over-2 text-wrap" :title="finalDraftHistory[0].targetFileList[0].remark">
                    {{ finalDraftHistory[0].targetFileList[0].remark }}
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>

          <!-- 翻译记录 -->
          <a-row v-if="showTransRecord">
            <a-col>
              <div class="order-info-item">
                <span class="label">{{ $t('trans_record.name') }}:</span>
                <div class="info" style="line-height: 15px">
                  <span
                    class="info ellipsis info-trans-status"
                    :style="[3, 4, 5, 6, 7].includes(transUploadStatus) ? 'color: red' : ''"
                    >{{ transUploadStatus | transUploadStatusText }}</span
                  >
                  <a-tooltip placement="top" :title="$t('preview')" class="_point" v-if="transHistoryPreviewIcon">
                    <img src="@/assets/image/orderInfo-preview.png" @click="previewTransRecord" />
                  </a-tooltip>
                </div>
              </div>
            </a-col>
          </a-row>

          <!-- 修改意见 -->
          <a-col :span="24" v-if="info.feedback">
            <div class="order-info-item">
              <span class="label pl-2" style="margin-left: -10px">{{ $t('orderInfo.suggestions') }}:</span>
              <div class="info">
                <div class="download-wrap">
                  <pre class="m0 text-wrap" v-if="info.feedback" v-html="info.feedback" />
                  <span v-else>---</span>

                  <div class="download-wrap mt-1" v-if="feedbackAttachmentList.length">
                    <a-space :size="10">
                      <a-button
                        v-for="item in feedbackAttachmentList"
                        :key="item.id"
                        class="p0"
                        type="link"
                        @click="linkDownload(item.url)"
                      >
                        {{ item.name }} <a-icon type="download" />
                      </a-button>
                    </a-space>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <!-- 修改交付日期 -->
          <a-col :span="24" v-if="info.feedbackDueDate">
            <div class="order-info-item">
              <span class="label pl-2" style="margin-left: -10px">{{ $t('orderInfo.new_delivery_time') }}:</span>
              <span class="info" :style="isModify('dueDate') ? 'color:#F56C6C;' : ''">
                {{ info.feedbackDueDate | dateFormat($t('dateFormat.c')) }}
              </span>
            </div>
          </a-col>
        </a-row>
        <!-- 反馈历史记录 -->
        <div v-if="isShowFR">
          <FRButton @my-click="showFeedbackRecords = !showFeedbackRecords" style="margin: 35px 0 15px 0" />
          <FeedbackRecords :show="showFeedbackRecords" :feedbackFileList="feedbackHistoryData" />
        </div>

        <!-- 历史终稿记录 -->
        <div v-if="finalDraftHistory.length > 0">
          <FinalDraftHistory class="mt-3" :finalDraftHistory="finalDraftHistory" />
        </div>
      </div>
      <!-- 操作 -->
      <template v-slot:footer v-if="freshFooter">
        <a-space :size="10">
          <!-- 查看报价单 -->
          <a-tooltip v-if="info.status === 1 && !$role('order_orderInfo_neoCode')">
            <template slot="title">
              {{ $t('orderInfo.waiting_quotation') }}
            </template>
            <a-button type="primary" v-if="$role('order_orderInfo_checkQuote')" :disabled="info.status === 1">
              {{ $t('orderInfo.btn_check_quote') }}
            </a-button>
          </a-tooltip>
          <a-button
            type="primary"
            v-else
            :disabled="info.status === 1 || !$is.Defined(info.quotePrice)"
            @click="handleQuoteDrawer"
          >
            {{ $t('orderInfo.btn_check_quote') }}
          </a-button>
          <!-- 在线咨询 -->
          <a-button @click="showOnlineConsult" type="primary">
            {{ $t('orderInfo.btn_online_consult') }}
          </a-button>
          <!-- 创建报价 -> 等待报价 -->
          <a-button
            type="primary"
            v-if="$role('order_orderInfo_createQuote') && info.status === 1"
            @click="handleCreateQuote"
          >
            {{ $t('orderInfo.btn_create_quote') }}
          </a-button>
          <!-- 终止订单 -> 生产中 & 终稿修改中 -->
          <a-button
            type="primary"
            v-if="$role('order_orderInfo_stopOrder') && [4, 6].includes(info.status)"
            @click="handleStopOrder"
          >
            {{ $t('orderInfo.btn_stop_order') }}
          </a-button>
          <!-- 取消订单 -> 等待报价 & 待确认报价-->
          <a-button
            type="primary"
            v-if="$role('order_orderInfo_cancelOrder') && [1, 2].includes(info.status)"
            @click="handleCancelOrder"
          >
            {{ $t('orderInfo.btn_cancel_order') }}
          </a-button>
          <!-- 下载终稿  已终止 & 已确认终稿 直接下载终稿否则弹出侧抽屉-->
          <a-button
            type="primary"
            v-if="$role('order_orderInfo_downloadFinish') && info.status >= 5"
            :loading="finishLoading"
            @click="handleDownloadFinish"
          >
            {{ $t('orderInfo.btn_download_finish') }}
          </a-button>
          <!-- 查看评价 -->
          <a-button
            type="primary"
            v-if="$role('order_orderInfo_checkRate') && info.status >= 9"
            @click="handleRate(true)"
          >
            {{ $t('orderInfo.btn_check_rate') }}
          </a-button>
          <!-- 评价 -->
          <a-button
            class="neo-btn-primary"
            v-if="$role('order_orderInfo_rate') && info.status >= 9"
            @click="handleRate(false)"
          >
            {{ $t('orderInfo.btn_rate') }}
          </a-button>

          <!-- 修改 -->
          <a-button
            class="neo-btn-primary"
            v-if="$role('order_orderInfo_cancelOrder') && [1, 2, 4, 6].includes(info.status)"
            @click="ModifyDrawer = true"
          >
            {{ $t('orderInfo.btn_modify_order') }}
          </a-button>

          <!-- 上传终稿 -->
          <a-button
            v-if="$role('order_orderInfo_uploadFinish') && [4, 5, 6].includes(info.orderStatus)"
            type="primary"
            @click="upload_visible = true"
          >
            {{ $t('orderInfo.btn_upload_finish') }}
          </a-button>

          <!-- 上传翻译记录 -->
          <template v-if="showTransRecordBtn">
            <NeoUploadButton
              :title="$t('orderInfo.upload_translation_records')"
              v-model="transRecordList"
              accept=".tmx,.zip"
              :showNote="false"
              :showFileList="false"
              :showIcon="false"
              :maxLength="transMaxLength"
              :maxSize="200"
              :neoStyle="true"
              btnStyle="border: 1px solid #4080FF;color:#4080FF"
              :loading="TLoading"
              @input="handleTransRecord"
            />
            <div class="_ml-5 transRecordList-tip" style="display: inline-block">
              <span class="transRecordList-tip-word-1">注：</span>
              <span class="transRecordList-tip-word-2">仅限格式为tmx和zip的文件</span>
            </div>
          </template>
        </a-space>

        <!-- 在线咨询抽屉 -->
        <OnlineConsultDrawer :visible.sync="onlineConsultDrawer" :info="info" @close="closeOnlineConsult" />

        <!-- 下载终稿抽屉 -->
        <DownloadEndDraftDrawer
          :info="info"
          :showAutoConfirmDate="showAutoConfirmDate"
          :autoConfirmDate="autoConfirmDate"
          :visible.sync="endDraftDrawer"
          :feedbackHistoryData="feedbackHistoryData"
          :finalDraftHistory="finalDraftHistory"
        />
        <!-- 查看评价 & 评价 -->
        <RateModal :visible.sync="rateModal" :type="rateModalType" :rateInfo="rateInfo" :info="info" />
        <!-- 创建报价 -->
        <CreateQuoteModal
          :info="info"
          :parent="parent"
          :visible.sync="quoteModal"
          @openQuoteDrawer="handleQuoteDrawer"
        />
        <!-- 报价单 -->
        <QuotationDrawer :info="info" :visible.sync="quoteDrawer" />

        <PreviewTransRecordDialog :show="PTRDShow" @close="handlePTRDClose" />

        <!-- 修改报价单 -->
        <ModifyOrderDrawer
          :visible.sync="ModifyDrawer"
          :info="info"
          @close="ModifyDrawer = $event"
          @confirm="handleUpdate"
        />

        <!-- 上传终稿 -->
        <UploadFinshDraft
          :visible.sync="upload_visible"
          :orderId="$route.query.id"
          @update:endDraftList="endDraftList = $event"
          @confirm="handleSubmitFinalDraft"
          @close="upload_visible = $event"
        />
      </template>
    </NeoPageLayout>
  </a-spin>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'
import { Icon } from 'ant-design-vue'
import { downloadUrl, diffDate } from '@/utils/utils'
import FeedbackRecords from './component/QuotationDrawer/FeedbackRecords/index'
import FRButton from './component/QuotationDrawer/FeedbackRecords/FRButton'
import TimeStep from './component/TimeStep'
import DownloadEndDraftDrawer from './component/DownloadEndDraftDrawer'
import RateModal from './component/RateModal'
import CreateQuoteModal from './component/CreateQuoteModal'
import QuotationDrawer from './component/QuotationDrawer'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import PreviewTransRecordDialog from './component/previewTransRecordDialog.vue'
const { localeDict } = window.$g
import debounce from 'lodash/debounce'
import sendFinalDraftDownloadEmail from '@/mixins/sendFinalDraftDownloadEmail.js'
import ModifyOrderDrawer from './component/ModifyOrderDrawer.vue'
import OnlineConsultDrawer from './component/OnlineConsultDrawer'
import FinalDraftHistory from './component/QuotationDrawer/FeedbackRecords/FinalDraftHistory.vue'
import UploadFinshDraft from './component/UploadFinshDraft.vue'

export default {
  name: 'OrderInfo',
  mixins: [sendFinalDraftDownloadEmail],
  // filters: {
  //   transUploadStatusText: function (v) {
  //     return ['解析中', '已上传', '解析失败', 'tmx文件内容为空！', '该tmx文件的语言对与当前订单不相符，请重新上传！'][
  //       v - 1
  //     ]
  //   },
  // },
  components: {
    TimeStep,
    DownloadEndDraftDrawer,
    RateModal,
    CreateQuoteModal,
    QuotationDrawer,
    FRButton,
    FeedbackRecords,
    NeoUploadButton,
    PreviewTransRecordDialog,
    ModifyOrderDrawer,
    OnlineConsultDrawer,
    FinalDraftHistory,
    UploadFinshDraft,
  },
  data() {
    return {
      numeral,
      loading: false,
      neoProduceLoading: false,
      // local字典表
      localeDict,
      // 订单详情
      info: {},
      // 自动倒计时时分秒
      autoConfirmDate: {},
      // 翻译进度表格数据
      neoProduceData: [],
      // 订单进度
      orderProgress: [],
      // 订单评价
      rateInfo: {},
      // 下载终稿抽屉
      endDraftDrawer: false,
      // 查看评价 & 评价
      rateModal: false,
      // 1 查看评价 2 评价
      rateModalType: 1,
      // 在线咨询抽屉
      onlineConsultDrawer: false,
      // 创建报价
      quoteModal: false,
      // 报价单
      quoteDrawer: false,
      // 反馈历史记录
      showFeedbackRecords: false,
      isShowFR: false,
      feedbackHistoryData: [],
      // 终稿
      endDraftList: [],
      // 翻译记录
      transRecordList: [],
      transUploadStatus: null,
      TLoading: false,
      PTRDShow: false,
      transMaxLength: 1,
      timer_: null,
      uploaded: false,
      transHistoryList: [],
      freshFooter: true,
      finishLoading: false,
      // 上传终稿对话框
      upload_visible: false,
      // 历史终稿记录
      finalDraftHistory: [],
      // 修改订单测抽屉
      ModifyDrawer: false,
      // 最新修改记录列表
      LatestModificationRecord: [],
    }
  },
  computed: {
    parent() {
      return this
    },
    isShowUploadFinish() {
      return this.$role('order_orderInfo_uploadFinish') && [4, 5, 6, '4', '5', '6'].includes(this.info.orderStatus)
    },
    order_orderInfo_uploadFinish() {
      return this.$role('order_orderInfo_uploadFinish')
    },
    order_orderInfo_downloadFinish() {
      return this.$role('order_orderInfo_downloadFinish')
    },
    status() {
      return this.info.status
    },
    isDownFinalDraft() {
      return this.info.status >= 8 || this.$g.isSup
    },
    // isShowFinalDraft() {
    //   return this.info.status >= 5
    // },
    // 附件 tag 1-附件 2-反馈附件
    attachmentList() {
      if (this.info.attachmentFileList && this.info.attachmentFileList.length) {
        return this.info.attachmentFileList
      } else {
        return []
      }
    },
    // 反馈附件
    feedbackAttachmentList() {
      if (this.info.feedbackFileList && this.info.feedbackFileList.length) {
        return this.info.feedbackFileList
      } else {
        return []
      }
    },
    // 币种单位
    currencyUnit() {
      if (this.$is.Defined(this.info.currency)) {
        const unit = this.$store.getters['app/getDictLabel']('CURRENCY', this.info.currency) || ''
        return unit.split(';')
      } else {
        return false
      }
    },
    // 是否展示倒计时
    showAutoConfirmDate() {
      if ([5, 7].includes(this.info.status) && this.info.autoConfirmDate) {
        return moment().isBefore(this.info.autoConfirmDate)
      }
      return false
    },
    isUploaded() {
      return this.transUploadStatus !== null
    },
    showTransRecord() {
      return this.$g.isSup && this.isUploaded
    },
    showTransRecordBtn() {
      return this.$g.isSup && [4, 5, 6, 7, 8, 9, 10, 11].includes(this.info?.status)
    },
    transHistoryPreviewIcon() {
      return [4, 5, 6, 7, 8, 9, 10, 11].includes(this.info?.status) && this.transUploadStatus === 2
    },
  },
  watch: {
    $route() {
      this.initPageData()
      clearInterval(this.timer_)
      console.warn('路由变化了')
    },
    transUploadStatus(v) {
      if ([2, 3, 4, 5, 6, 7].includes(v)) {
        clearInterval(this.timer_)
        console.warn('终止1')
      }
    },
  },
  provide: function () {
    return {
      getOrderInfo: this.getOrderInfo,
    }
  },
  methods: {
    // 在线咨询
    showOnlineConsult() {
      this.onlineConsultDrawer = true
      window.localStorage.setItem('noConsultDrawer', 'false')
    },

    closeOnlineConsult() {
      this.onlineConsultDrawer = false
      window.localStorage.setItem('noConsultDrawer', 'true')
    },
    goList(err) {
      if (err?.code === 120001004) {
        setTimeout(() => {
          this.$router.push({ path: this.$g.route.project_list })
        }, 1000)
      }
    },
    async getOrderInfo() {
      try {
        const info = await this.$http({
          key: 'orderProcessInfo',
          params: {
            id: this.$route.query.id,
          },
        })
        this.info = info
        console.warn('this.info :>> ', this.info)
        return this.info
      } catch (err) {
        this.$httpNotify(err)
        return null
      }
    },
    handlePTRDClose() {
      this.PTRDShow = false
    },
    previewTransRecord() {
      console.warn('预览')
      this.PTRDShow = true
    },
    // 获取领域
    getField(val) {
      const data = this.$store.getters['app/getIndustryName'](val)
      if (data) {
        const result = data.length === 2 ? `${data[0]} / ${data[1]}` : `${data[1]} / ${data[2]}`
        return result
      } else {
        return null
      }
    },

    linkDownload(url) {
      downloadUrl(url)
    },

    // 取消订单
    handleCancelOrder() {
      this.$confirm({
        title: this.$t('orderInfo.cancel_order_title'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        content: this.$t('orderInfo.cancel_order_info'),
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onOk: async () => {
          try {
            await this.$http('orderProcessWithdraw', { id: this.info.id })
            this.initPageData()
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    },

    // 终止订单
    handleStopOrder() {
      this.$confirm({
        title: this.$t('orderInfo.stop_order_title'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        content: this.$t('orderInfo.stop_order_info'),
        cancelText: this.$t('orderInfo.stop_order_ok'),
        okText: this.$t('orderInfo.stop_order_cancel'),
        onCancel: async () => {
          try {
            await this.$http('orderProcessTerminate', { id: this.info.id })
            this.initPageData()
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    },

    // 下载终稿
    handleDownloadFinish: debounce(async function () {
      // [8, 9].includes(this.info.status) || this.info.status > 9
      if (this.isDownFinalDraft) {
        // 已终止 & 已确认终稿 || 用户是供应商PM/SPM 直接下载终稿否则弹出侧抽屉
        try {
          this.finishLoading = true
          const { targetFileList } = await this.$http({
            key: 'orderProcessInfo',
            params: {
              id: this.$route.query.id,
            },
          })
          if (targetFileList && targetFileList.length) {
            // eslint-disable-next-line no-unused-vars
            targetFileList.forEach(async ({ url }, idx) => {
              downloadUrl(url)
              await this.sendFinalDraftDownloadEmail()
              this.finishLoading = false
            })
          } else {
            this.finishLoading = false
            this.$message.warn(this.$t('tips.tip2'))
          }
        } catch (err) {
          this.$httpNotify(err)
        }
      } else {
        this.endDraftDrawer = true
      }
    }, 200),

    // 历史终稿记录
    async getFinalDraftHistory() {
      try {
        const data = await this.$http({
          key: 'finalDraftHistoryAll',
          params: {
            orderId: this.$route.query.id,
          },
        })
        this.finalDraftHistory = data
      } catch (error) {
        this.$httpNotify(error)
      }
    },

    // 查看评价 & 评价 isReadOnlay
    async handleRate(isReadOnlay) {
      try {
        const info = await this.$http({
          key: 'orderProcessRemarkInfo',
          params: {
            id: this.info.id,
          },
        })
        if (isReadOnlay) {
          if (!this.$is.Defined(info) || !this.$is.Defined(info.quality)) {
            return this.$message.error(this.$t('orderInfo.no_rate_info'))
          } else {
            this.rateInfo = info
            this.rateModal = true
            this.rateModalType = 1
          }
        } else {
          this.rateInfo = info || {}
          this.rateModal = true
          this.rateModalType = info && this.$is.Defined(info.quality) ? 1 : 2
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 创建报价
    handleCreateQuote() {
      this.quoteModal = true
    },

    // 查看报价单
    handleQuoteDrawer() {
      this.quoteDrawer = true
    },

    // 获取订单进度
    async orderProcessProgress() {
      try {
        const data = await this.$http({
          key: 'orderProcessProgress',
          params: {
            id: this.info.id,
          },
        })
        // 1，2，4，5，6，7 需要补全 等待状态
        let steps = data.map((v) => ({
          id: v.id,
          createDate: v.createDate ? moment(v.createDate).format(this.$t('dateFormat.a')) : null,
          creatorName: v.creatorName,
          creatorPhone: v.creatorPhone,
          info:
            v.targetStatus === 3 && this.$is.Defined(this.info.quotePrice)
              ? this.localeDict.orderProgressStatus[`${v.targetStatus}_2`]
              : v.targetStatus === 3 && !this.$is.Defined(this.info.quotePrice)
              ? this.localeDict.orderProgressStatus[`${v.targetStatus}_2`]
              : this.localeDict.orderProgressStatus[v.targetStatus],
        }))

        if (data.length) {
          const status = data[data.length - 1].targetStatus
          if ([1, 2, 4, 5, 6, 7].includes(status)) {
            steps = [
              ...steps,
              {
                active: true,
                type: 'next',
                info: this.localeDict.orderProgressStatus[`${status}_next`],
              },
            ]
          }
        }
        this.orderProgress = steps
        !this.orderProgress.length && this.TimeStepLoaded()
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 获取翻译进度
    async getNeoProduceProgress() {
      this.neoProduceData = []
      // if (!this.$is.Defined(this.info.jobId)) return
      try {
        this.neoProduceLoading = true
        const data = await this.$http('orderNeoProduceProgress', {
          jobId: this.info.jobId,
          version: this.info.produceVersion,
          orderId: this.$route.query.id,
        })
        if (data.length) {
          this.neoProduceData = data.map((v) => ({
            ...v,
            weightWordCount: v.weightWordCount ? numeral(v.weightWordCount).format('0.[00]') : null,
            stageProgress:
              this.info.produceVersion === 1
                ? Number(v.stageProgress)
                : Number(numeral(v.stageProgress * 100).format('0.[0]')),
          }))
        } else {
          this.neoProduceData = this.info.targetCodeList.split(',').map((code) => ({
            currentStage: null,
            stageProgress: '0.0',
            targetCode: code,
            weightWordCount: null,
          }))
        }

        this.neoProduceLoading = false
      } catch (err) {
        this.neoProduceLoading = false
        this.$httpNotify(err)
      }
    },
    isOpenDrawer() {
      const action = this.$route.query.action
      const go = () => {
        if (action == '1') {
          this.endDraftDrawer = true
        } else if (action == '2') {
          // callback监听this.info，当它有数据时弹出抽屉
          const callback = () => {
            if (Object.keys(this.info).length !== 0) {
              this.handleQuoteDrawer()
            } else {
              setTimeout(() => {
                callback()
              }, 0)
            }
          }
          callback()
        }
      }
      if (window.name == '') {
        // 在首次进入window页面时我们给window.name设置一个固定值(isRefresh)
        console.warn('首次被加载')
        window.name = 'isRefresh'
        go()
      } else if (window.name == 'isRefresh') {
        console.warn('页面被刷新')
      }

      const bool = window.localStorage.getItem('noConsultDrawer') === 'true' ? false : true
      if (action == '3' && bool) {
        this.showOnlineConsult()
      }
    },

    // 获取订单详情
    initPageData: debounce(async function (freshFooter) {
      try {
        this.endDraftDrawer = false
        this.loading = true
        const info = await this.$http({
          key: 'orderProcessInfo',
          params: {
            id: this.$route.query.id,
          },
        })
        this.info = info
        this.loading = false
        this.getFeedbackHistory()
        // 待确认终稿 & 有autoConfirmDate - 当前时间倒推
        if ([5, 7].includes(info.status) && info.autoConfirmDate) {
          this.autoConfirmDate = diffDate(moment().format('YYYY-MM-DD HH:mm:ss'), info.autoConfirmDate)
        }
        // 获取翻译进度
        this.getNeoProduceProgress()
        // 获取订单进度
        this.orderProcessProgress()

        if (freshFooter) {
          this.freshFooter = false
          setTimeout(() => {
            this.freshFooter = true
          }, 200)
        }
        this.isOpenDrawer()
      } catch (err) {
        this.loading = false
        this.goList(err)
        this.$httpNotify(err)
      }
    }, 500),
    // 获取订单反馈历史
    async getFeedbackHistory() {
      try {
        const info = await this.$http({
          key: 'feedbackHistory',
          params: {
            id: this.$route.query.id,
          },
        })
        this._isShowFR(info ?? [])
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 是否可以显示历史反馈记录
    _isShowFR(feedbackFileList) {
      // 1=>供应商,3=>下单方
      const status = this.info.status
      let result = false
      if (feedbackFileList?.length >= 1) {
        if (this.$g.isCust && status >= 9) {
          result = true
        } else if (this.$g.isSup) {
          result = true
        } else {
          result = false
        }
      } else {
        result = false
      }
      this.isShowFR = result
      this.feedbackHistoryData = feedbackFileList
    },
    // 上传终稿
    handleSubmitFinalDraft() {
      this.initPageData(true)
      this.getFinalDraftHistory()
      this.$message.success(this.$t('orderInfo.submitFinalDraftSuccess'))
    },
    setTimerHandle: debounce(function () {
      this.getOrderParseStatus()
      this.timer_ = setInterval(() => {
        this.getOrderParseStatus()
      }, 1000 * 1.5)
    }, 40),
    // 上传翻译记录
    async handleTransRecord(data) {
      this.TLoading = true
      if (data[0].status == 'done') {
        let isUploaded = await this.checkIsExistTranslationHistory()
        if (isUploaded) {
          this.$confirm({
            title: this.$t('tip'),
            centered: false,
            class: 'coConfig_AddManager_confirm_class',
            icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
            content: '您已上传过翻译记录，再次上传时，旧数据就被覆盖，是否继续？',
            okText: this.$t('confirm'),
            cancelText: this.$t('cancel'),
            onOk: () => {
              this.saveTranslationHistory(data)
            },
            onCancel: () => {
              this.transRecordList = []
              this.TLoading = false
            },
          })
        } else {
          this.saveTranslationHistory(data)
        }
      }
    },
    async getOrderParseStatus() {
      try {
        const orderId = this.$route.query.id
        if (!orderId) {
          return
        }
        const params = { orderId }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('getOrderParseStatus', params)
        this.transUploadStatus = data
        console.warn('getOrderParseStatus---')
        return data
      } catch (err) {
        this.$httpNotify(err)
        return 0
      }
    },
    async checkIsExistTranslationHistory() {
      try {
        const params = { orderId: this.$route.query.id }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('checkIsExistTranslationHistory', params)
        return data
      } catch (err) {
        this.$httpNotify(err)
        return 0
      }
    },
    async saveTranslationHistory(data) {
      const { url, name } = data[0]

      const type = name.split('.')[1].toLowerCase() === 'zip' ? 1 : 2
      const params = {
        fileUrl: url,
        orderId: this.$route.query.id,
        type,
        name,
      }
      try {
        await this.$http('saveTranslationHistory', params)
        this.$message.success(this.$t('orderInfo.transRecordSuccess'))
        this.setTimerHandle()
      } catch (err) {
        this.$httpNotify(err)
      } finally {
        this.transRecordList = []
        this.TLoading = false
      }
    },
    isShowDrawer() {
      // author wjj
      const action = this.$route.params.action

      if (action == '1') {
        this.endDraftDrawer = true
      } else if (action == '2') {
        // callback监听this.info，当它有数据时弹出抽屉
        const callback = () => {
          if (Object.keys(this.info).length !== 0) {
            this.handleQuoteDrawer()
          } else {
            setTimeout(() => {
              callback()
            }, 0)
          }
        }
        callback()
      }
    },
    TimeStepLoaded() {
      setTimeout(() => {
        this.isShowDrawer()
      })
    },

    // 刷新订单信息
    handleUpdate() {
      this.initPageData()
      this.getLatestModificationRecord()
    },

    // 获取最新修改记录
    async getLatestModificationRecord() {
      try {
        this.LatestModificationRecord = await this.$http({
          key: 'lastRecordList',
          params: {
            orderId: this.$route.query.id,
          },
        })
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    isModify(value) {
      let bool = []
      this.LatestModificationRecord.forEach((_) => {
        if (_.modifyField == value) {
          bool[value] = true
        }
      })
      return bool[value]
    },
  },
  async mounted() {
    this.initPageData()
    this.getLatestModificationRecord()
    await this.getOrderParseStatus()
    this.getFinalDraftHistory()
    this.$bus.$on('_refresh_order_info_', this.initPageData)
  },
  destroyed() {
    window.name = ''
  },
  beforeDestroy() {
    this.$bus.$off('_refresh_order_info_', this.initPageData)
    console.warn('beforeDestroy--')
    clearInterval(this.timer_)
    window.localStorage.removeItem('noConsultDrawer')
    console.warn('终止2')
  },
}
</script>
