import { render, staticRenderFns } from "./TimeStep.vue?vue&type=template&id=228925b3&scoped=true&"
import script from "./TimeStep.vue?vue&type=script&lang=js&"
export * from "./TimeStep.vue?vue&type=script&lang=js&"
import style0 from "./TimeStep.vue?vue&type=style&index=0&id=228925b3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228925b3",
  null
  
)

export default component.exports